.FileList {
  flex-grow: 1;
  min-height: 400px;
  margin: 0 auto;
  text-align: left;
  overflow-y: scroll!important;
  width: 100%;
}

.file-item {
  padding: 15px;
  border-bottom: solid 1px;
}

.file-item p {
  margin: 0;
}
