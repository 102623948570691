.App {}

.App-Container {
  max-width: 720px !important;
  display: flex !important;
  flex-direction: column;
  max-height: calc(100vh - 96px);
  gap: 10px;
  margin-top: 70px;
}
